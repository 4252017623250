<template>
  <div class="mt-4">
    <div class="my-4 d-flex align-items-center justify-content-between">
      <h1 class="page-title">
        <b-icon icon="terminal-fill" />
        {{ qre.name }}
      </h1>
      <b-button
        class="mr-2 shadow-sm"
        variant="primary"
        pill
        @click="$router.go(-1)"
      >
        <b-icon class="mr-2" icon="arrow90deg-left" />
        {{ $t("back") }}
      </b-button>
    </div>

    <b-card
      border-variant="light"
      class="mt-4 shadow-sm card-rounded"
      :title="$t('rescueConsole.title')"
    >
      <!-- terminal -->
      <b-overlay :opacity="0.95" :show="loadingInfo" class="mt-3 mb-4">
        <div id="terminal" />
      </b-overlay>

      <!-- countdowntimer -->
      <div v-if="loadingInfo" class="text-center mb-4">
        <b-card-text>
          {{ remainingMin }} :
          {{ remainingSec <= 9 ? "0" + remainingSec : remainingSec }}
          {{ $t("rescueConsole.timeUntilTimeout") }}
        </b-card-text>
      </div>

      <b-alert :show="showError" dismissible variant="danger">
        {{ $t("rescueConsole.requestHasTimeout") }}
      </b-alert>

      <!-- input txt + submit cmd btn -->
      <div>
        <b-form @submit.prevent="basicConsole">
          <div class="d-flex justify-content-between">
            <b-input-group>
              <b-form-input
                ref="txt"
                v-model="command"
                :disabled="loadingInfo"
                :placeholder="$t('rescueConsole.command')"
              />
              <b-input-group-append>
                <b-button :disabled="loadingInfo" @click="clearInput">
                  <b-icon-x />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button
              :disabled="loadingInfo || !command"
              class="ml-3 px-3"
              pill
              type="submit"
              variant="success"
              >{{ $t("rescueConsole.sendBtn") }}
            </b-button>
          </div>
        </b-form>
      </div>
    </b-card>
  </div>
</template>
<script>
import "xterm/css/xterm.css";
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";

export default {
  name: "TerminalQre",
  data() {
    return {
      id: this.$route.params.id,
      consoleListener: null,
      command: null,
      qre: {},
      loadingInfo: false,
      seconds: 180,
      resetTime: 180,
      timeoutInterval: null,
      showError: false,
    };
  },
  mounted() {
    this.getQre();

    this.$term = new Terminal({
      cursorBlink: true,
      cursorStyle: "bar",
      fontWeight: "bold",
      rows: 25,
    });
    this.$fitAddon = new FitAddon();
    this.$term.loadAddon(this.$fitAddon);
    this.$term.open(document.getElementById("terminal"));
    this.$fitAddon.fit();

    this.consoleListener = this.$term.onData(this.basicConsole);
  },

  computed: {
    remainingMin() {
      return Math.floor(this.seconds / 60);
    },
    remainingSec() {
      return this.seconds % 60;
    },
  },
  methods: {
    async getQre() {
      try {
        const { data: qre } = await this.$http.get(
          `${this.$cfg.BASE_QRE_MANAGER_URL}/v0/qres/${this.$route.params.id}`,
          {
            errors: {
              404: () => true,
            },
          }
        );

        this.qre = qre;
      } catch (err) {
        if (err.response.status === 404) {
          await this.$router.push("/admin/qres");
        }
        if (err.response) console.log(err.response);
      }
    },

    async basicConsole() {
      if (this.command) {
        this.loadingInfo = true;
        this.setCountDown();
        try {
          const { data } = await this.$http.post(
            `${this.$cfg.BASE_QRE_MANAGER_VERSION_URL}/qres/${this.$route.params.id}/executeRescueConsoleCommand`,
            {
              request: this.command,
            }
          );

          this.$term.write(data);
          this.consoleListener?.dispose();
        } catch (e) {
          const msg = !e ? "Error!" : e.message || e;
          this.$term.write(`\x1b[31m${msg.replace("\n", "\r\n")}\x1b[m`);
          this.consoleListener?.dispose();
        } finally {
          this.consoleListener = this.$term.onData(this.basicConsole);
          this.$term.write("\r\n");
          this.consoleListener?.dispose();
          this.loadingInfo = false;
          this.command = "";
          this.seconds = this.resetTime;
          clearInterval(this.timeoutInterval);
          // keep focus on input
          this.$nextTick(() => this.$refs.txt.focus());
        }
      }
    },

    clearInput() {
      this.command = "";
    },

    countdownTimer() {
      if (this.loadingInfo && this.seconds > 0) {
        this.seconds--;
      }
      if (this.seconds === 0) {
        this.loadingInfo = false;
        clearInterval(this.timeoutInterval);
        setTimeout(this.displayError, this.seconds + 1);
      }
    },

    setCountDown() {
      this.timeoutInterval = setInterval(this.countdownTimer, 1000);
    },

    displayError() {
      this.showError = true;
    },
  },
};
</script>
